import { Flex, Icon, PairMembership, Paragraph } from '@components';
import { useIsMobile, useTranslation } from '@utils/hooks';
import styles from './PairPlusDetail.module.scss';
import { emphasizeWord } from './RewardsDetail';

export const PairPlusDetail = () => {
	const isMobile = useIsMobile();

	const { translator: t } = useTranslation();

	return (
		<div className={styles.container}>
			<header>
				<Flex gap={isMobile ? 4 : 5} align='center'>
					<Flex justify='center' align='center' gap={4}>
						<Icon shape='circle' label='pair-plus-badge'>
							<img src={`/pair-plus.svg`} alt='status' />
						</Icon>
						<Flex column className={styles.coloredText}>
							<PairMembership />
							<Paragraph>{t('status').toUpperCase()}</Paragraph>
						</Flex>
					</Flex>
					<div className={styles.verticalSeparator} />
					<Paragraph className={styles.title}>
						{emphasizeWord(
							t('you-unlocked-pair-plus-perks'),
							t('pair-plus'),
							<span className={styles.emphasizeText}>{t('pair-plus')}</span>
						)}
					</Paragraph>
				</Flex>
			</header>
			<Flex column className={styles.content}>
				<Paragraph className={styles.subtitle}>{t('you-unlocked-pair-plus-perks')}</Paragraph>
				<ul className={styles.benefits}>
					<li>{t('monthly-store-credit')}</li>
					<li>{t('vip-benefit-4')}</li>
					<li>{t('vip-benefit-3')}</li>
					<li>{t('vip-benefit-5')}</li>
					<li>{t('vip-benefit-6')}</li>
				</ul>
			</Flex>
		</div>
	);
};
