import { ComponentProps } from 'react';
import { CartReview, Flex } from '@components';
import { translate } from '@utils/locales';
import { useTranslation } from '@utils/index';

const getMockedReviews = (locale: string) => {
	return [
		{
			description: translate('mocked-review-1-description', locale),
			name: 'Anna',
			location: 'California',
			rating: 5,
		},
		{
			description: translate('mocked-review-2-description', locale),
			name: 'Colby',
			location: 'Maine',
			rating: 5,
		},
		{
			description: translate('mocked-review-3-description', locale),
			name: 'Kelsey',
			location: 'Indiana',
			rating: 5,
		},
	];
};

interface CartReviewsProps {
	reviews?: ComponentProps<typeof CartReview>[];
}
function CartReviews({ reviews }: CartReviewsProps) {
	const { locale } = useTranslation();
	const safeReviews = reviews ?? getMockedReviews(locale);
	return (
		<Flex column fullWidth gap={4} style={{ marginTop: '1.6rem' }}>
			{safeReviews.slice(-1).map((review, index) => (
				<CartReview {...review} key={`${review.name}--${index}`} />
			))}
		</Flex>
	);
}

export default CartReviews;
