import { Fragment, memo } from 'react';
import {
	Accordian,
	DiamondFace,
	Flex,
	HeartFace,
	OblongFace,
	OvalFace,
	Paragraph,
	RoundFace,
	SquareFace,
	TriangleFace,
} from '@components';
import { FrameSizingData } from '@ts/product';
import { FACE_SHAPES, getIdeadForFaceShape } from '@constants';

import styles from './FitDetails.module.scss';
import { useTranslation } from '@utils/index';

const FACE_SHAPE_ICONS = {
	[FACE_SHAPES.DIAMOND]: DiamondFace,
	[FACE_SHAPES.HEART]: HeartFace,
	[FACE_SHAPES.OBLONG]: OblongFace,
	[FACE_SHAPES.OVAL]: OvalFace,
	[FACE_SHAPES.ROUND]: RoundFace,
	[FACE_SHAPES.SQUARE]: SquareFace,
	[FACE_SHAPES.TRIANGLE]: TriangleFace,
};

const getTranslatedTexts = translator => {
	return {
		details: translator('details'),
		aboutProduct: (productName: string) => translator('about-product', { productName }),
		fitAndMaterial: translator('fit-and-material'),
		frameMixMaterialDescription: translator('frame-mix-material-description'),
		frameSinlgeMaterialDescription: translator('frame-sinlge-material-description'),
		frameFitDescription: (
			frameName: string,
			lensWidth: number,
			frameBridge: number,
			frameTemple: number,
			lensHeight: number,
			frameWidth: number,
			units: string
		) =>
			translator('frame-fit-description', {
				frameName,
				lensWidth,
				frameBridge,
				frameTemple,
				lensHeight,
				frameWidth,
				units,
			}),
		idealFor: translator('ideal-for'),
	};
};

const FitDetails = ({
	name,
	description,
	tags,
	handle,
	frameSizings,
	isOpen = false,
	isMixedMaterial = false,
	onClick,
}: {
	name: string;
	description: string;
	handle: string;
	tags: string[];
	frameSizings: FrameSizingData;
	isOpen?: boolean;
	isMixedMaterial?: boolean;
	onClick: () => void;
}) => {
	const { frame, lens, units } = frameSizings;
	const { translator, locale } = useTranslation();
	const translations = getTranslatedTexts(translator);
	const IDEAL_FOR_FACE_SHAPES = getIdeadForFaceShape(locale);

	return (
		<Accordian title={translations.details} isOpen={isOpen} onClick={onClick}>
			<Flex className={styles.content}>
				<Flex column className={styles.container}>
					<Paragraph className={styles.title}>{translations.aboutProduct(name)}</Paragraph>
					<Paragraph>{description}</Paragraph>
				</Flex>
				<Flex column className={styles.container}>
					<Paragraph className={styles.title}>{translations.fitAndMaterial}</Paragraph>
					<Paragraph>
						{isMixedMaterial ? translations.frameMixMaterialDescription : translations.frameSinlgeMaterialDescription}
						{translations.frameFitDescription(
							name,
							lens.width,
							frame.bridge,
							frame.temple,
							lens.height,
							frame.width,
							units
						)}
					</Paragraph>
				</Flex>
				<Flex column className={styles.container}>
					<Paragraph className={styles.title}>{translations.idealFor}</Paragraph>
					<Paragraph>{IDEAL_FOR_FACE_SHAPES[handle]}</Paragraph>
					<Flex className={styles.faceShape}>
						{tags?.map(tag => {
							return FACE_SHAPE_ICONS[tag] ? <Fragment key={tag}>{FACE_SHAPE_ICONS[tag]()}</Fragment> : null;
						})}
					</Flex>
				</Flex>
			</Flex>
		</Accordian>
	);
};

export default memo(FitDetails);
