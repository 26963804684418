import { useRef } from 'react';
import { useRouter } from 'next/router';
import { nanoid } from 'nanoid';
import { useQuery } from '@tanstack/react-query';
import { AnimatePresence, m, useReducedMotion } from 'framer-motion';
import { Button, Caption, CartNavButton, CircleTimer, Close, Flex, FreeShippingTracker, MicroCard } from '@components';
import { calculateCartQuantity, getThreshold } from '@utils/cart';
import { useCartContext } from '@utils/context';
import { useClickOutside, useTranslation } from '@utils/hooks';
import { getMicrocartAnimation } from '@utils/motions';
import { getSiteSettings } from '@services/contentful';
import { useCart } from '@services/shopify';
import styles from './Microcart.module.scss';

const TIMER_DURATION = 5;

const getTranslatedTexts = translator => {
	return {
		addedToCart: translator('added-to-cart'),
		viewMicroCartQuantaty: (cartQuantity: number) => translator('view-micro-cart-quantaty', { cartQuantity }),
		closeMicrocartPopup: translator('close-microcart-popup'),
	};
};

const Microcart = () => {
	const microcartRef = useRef(null);
	const { locale } = useRouter();
	const { data } = useCart();
	const { microcartProduct, setMicrocartProduct } = useCartContext();
	const { data: siteSettings } = useQuery(['siteSettings'], async () => await getSiteSettings());
	const prefersReducedMotion = useReducedMotion();
	const threshold = getThreshold(locale, siteSettings) ?? 0;
	const cartQuantity = data?.lines?.length ? calculateCartQuantity(data.lines) : 0;
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);

	useClickOutside(microcartRef, () => setMicrocartProduct(null));

	return (
		<div className={styles['container']} data-microcart-open={!!microcartProduct} data-testid='microcart'>
			<CartNavButton href='/cart' />
			<AnimatePresence>
				{!!microcartProduct && (
					<m.div className={styles['microcart']} ref={microcartRef} {...getMicrocartAnimation(prefersReducedMotion)}>
						<div className={styles['caret']} />
						<Flex fullWidth align='center' gap={3} className={styles['header']}>
							<CircleTimer
								key={`circle-timer-${data?.subtotal}`}
								initialPlayState={!!microcartProduct}
								seconds={TIMER_DURATION}
								callback={() => setMicrocartProduct(null)}
							/>
							<Caption>{translations.addedToCart}</Caption>
							<Close
								label={translations.closeMicrocartPopup}
								onClick={() => setMicrocartProduct(null)}
								data-microcart-close
							/>
						</Flex>
						<MicroCard key={`line-${nanoid()}`} data={microcartProduct} />
						{!!threshold && (
							<FreeShippingTracker
								freeShippingThreshold={threshold}
								cartTotal={data?.subtotal}
								showMarginPadding={false}
								isMicrocartStyle={true}
								locale={locale}
							/>
						)}
						<Button
							fullWidth
							size='small'
							label={translations.viewMicroCartQuantaty(cartQuantity)}
							href='/cart'
							data-microcart-view
						/>
					</m.div>
				)}
			</AnimatePresence>
		</div>
	);
};

export default Microcart;
