import { useCallback, useEffect, useRef } from 'react';
import { useClearRefinements, useCurrentRefinements } from 'react-instantsearch';
import cn from 'classnames';
import { useFilterContext } from '@context';
import { RefinementChip, TypographyButton } from '@components';
import { filterRefinements, normalizeCollectionRefinement } from '@utils/algolia';
import { capitalize } from '@utils/strings';
import { useTranslation } from '@utils/index';
import styles from './CurrentRefinements.module.scss';

const getTranslatedTexts = translator => {
	return {
		clear: translator('clear'),
	};
};

const CurrentRefinements = ({ className = null, ...rest }: { className?: string }) => {
	const listRef = useRef<HTMLLIElement>(null);
	const { collections, scrollToTop } = useFilterContext();
	const { items, refine } = useCurrentRefinements();
	const { refine: clearRefinements } = useClearRefinements();
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);

	const handleClearRefinements = useCallback(() => {
		scrollToTop();
		clearRefinements();
	}, [clearRefinements, scrollToTop]);

	const refinements = !!items?.length
		? items.map(index => {
				const { refinements: originalRefinements } = index;
				const filteredRefinements = filterRefinements(originalRefinements);

				return filteredRefinements.map(item => {
					const { value, label, attribute } = item;
					let formattedLabel = capitalize(label.replace(/\["|"\]+/g, ''), true);
					if (attribute === 'product_type') {
						formattedLabel = collections && normalizeCollectionRefinement(collections, label);
					}

					return (
						<li role='listitem' key={value}>
							<RefinementChip refine={refine} items={originalRefinements} item={item}>
								{formattedLabel}
							</RefinementChip>
						</li>
					);
				});
			})
		: null;

	useEffect(() => {
		if (listRef.current) {
			listRef.current.scrollIntoView({ behavior: 'instant', block: 'nearest', inline: 'end' });
		}
	}, [items]);

	return (
		<div className={cn(styles['container'], { [className]: !!className })} {...rest}>
			<ul role='list'>
				{refinements}
				<li ref={listRef} />
			</ul>
			<TypographyButton small className={styles['clear']} onClick={() => handleClearRefinements()}>
				{translations.clear}
			</TypographyButton>
		</div>
	);
};

export default CurrentRefinements;
