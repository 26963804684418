import React from 'react';
import { useRouter } from 'next/router';
import { LOCALE_CODES } from '@constants';
import styles from './SMSFrame.module.scss';

const SMSFrame = () => {
	const { locale } = useRouter();

	const localeUrlMap = {
		[LOCALE_CODES.CA]: 'https://paireyewear.attn.tv/p/o6F/landing-page',
		[LOCALE_CODES.GB]: 'https://paireyewear.attn.tv/p/2aK/landing-page',
		[LOCALE_CODES.AU]: 'https://paireyewear.attn.tv/p/mkx/landing-page',
		default: 'https://paireyewear.attn.tv/p/pr0/landing-page',
	};

	const iframeUrl = localeUrlMap[locale] || localeUrlMap['default'];

	return (
		<div className={styles.container}>
			<iframe src={iframeUrl}></iframe>
		</div>
	);
};

export default SMSFrame;
