import { getShopifyIdFromGID } from '@utils/shopify';
import useOrders from './useOrders';
import useOrderDetails from './useOrderDetails';

type useLastOrderDetails = { id: string };

const useLastOrderDetails = ({ id }: useLastOrderDetails) => {
	const userId = getShopifyIdFromGID(id);
	const { data: orderData, isLoading } = useOrders({ userId, page: 1 });
	const orderNumber = orderData?.orders?.[0]?.order_number;
	const { data: order, isLoading: isDetailLoading } = useOrderDetails({ id: orderNumber });
	return { order, isLoading, isDetailLoading };
};
export default useLastOrderDetails;
