import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { useCustomer } from '@services/shopify';
import AccountCard, { AccountCardProps } from '../AccountCard/AccountCard';
import styles from './PairPlusCard.module.scss';

const PairPlusCard = ({ ...rest }: Omit<AccountCardProps, 'children'> & { userId: string }) => {
	const { data: user, isLoading } = useCustomer();
	const isMembership = useFeatureIsOn('is-membership');

	if (!isLoading && !user) return null;

	const userId = user?.id?.split('/').pop();

	const storeUrl = process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN;

	const primaryHref = `https://${storeUrl}/apps/subscribfy-api/store/manage-membership-dashboard?cid=${userId}&exm=1&hash=${user?.membershipHash}`;

	if (!user.isMembershipVip || !isMembership) return null;

	return (
		<AccountCard
			className={styles.rewardContainer}
			primaryAction='pair-plus'
			secondaryAction='question-membership'
			type='pair-plus'
			isDataLoading={isLoading}
			primaryHref={primaryHref}
			{...rest}
		/>
	);
};

export default PairPlusCard;
