import React from 'react';
import { Button } from '@components';
import { useCartContext } from '@context';
import { useCart } from '@services/shopify';
import { useTranslation } from '@utils/index';

const getTranslatedTexts = translator => {
	return {
		wipeCart: translator('wipe-cart-emoji'),
	};
};

/**
 * Clears all line items from the cart
 */
const WipeCart = () => {
	const { data: cart } = useCart();
	const { handleCartRemove } = useCartContext();
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);

	return (
		<Button
			fullWidth
			color='transparent-dark'
			label={translations.wipeCart}
			onClick={() => handleCartRemove(cart.lines.map(line => line.id))}
		/>
	);
};

export default WipeCart;
