import { CutPaper, Heading } from '@components';
import createCutPaperPath from '@utils/create-cut-paper';
import colors from '@styles/export.module.scss';
import { useTranslation } from '@utils/index';
import styles from './BaseFrameHeading.module.scss';

type Props = {
	title: string;
	paperColor?: string;
};

const getTranslatedTexts = translator => {
	return {
		getStartedSelecting: translator('get-started-selecting'),
		baseFrameBelow: translator('base-frame-below'),
	};
};

const BaseFrameHeading = ({ title, paperColor = colors.blue2 }: Props) => {
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);
	const path1 = createCutPaperPath('apple');
	const path2 = createCutPaperPath('banana');

	return (
		<div className={styles.container}>
			<CutPaper padding={'4px 3px 8px 8px'} cutPaperPath={path1} backgroundColor={paperColor}>
				<CutPaper padding={'1px 4px 4px 6px'} cutPaperPath={path2}>
					<Heading tag='h3' style={{ margin: '0' }}>
						{title}
					</Heading>
				</CutPaper>
			</CutPaper>
			<Heading className={styles.subHeader} style={{ maxWidth: '32rem', marginTop: '2.4rem' }}>
				{translations.getStartedSelecting}
				<wbr />
				{translations.baseFrameBelow}
			</Heading>
		</div>
	);
};

export default BaseFrameHeading;
