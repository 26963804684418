import { useForm } from 'react-hook-form';
import { Button, Caption, Flex, Heading, Input, Paragraph, Tooltip } from '@components';
import variables from '@styles/export.module.scss';
import { MIN_VALUE } from '@constants';
import { useTranslation } from '@utils/index';

type OrderNumberFormProps = {
	setOrder: (order: string) => void;
};

const getTranslatedTexts = translator => {
	return {
		enterOrderInfo: translator('enter-order-info'),
		linkPdWithPair: translator('link-pd-with-pair'),
		enterOrderNumber: translator('enter-order-number'),
		orderNumberDigitRestriction: translator('order-number-digit-restriction'),
		orderNumberLengthRestriction: translator('order-number-length-restriction'),
		orderNumber: translator('order-number'),
		submit: translator('submit'),
		orderConfirmationEmail: translator('order-confirmation-email'),
		helpFindOrder: translator('help-find-order'),
	};
};

const OrderNumberForm = ({ setOrder }: OrderNumberFormProps) => {
	const {
		register,
		handleSubmit,
		watch,
		formState: { errors },
	} = useForm();
	const orderNumber = watch('orderNumber');
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);

	const onSubmit = data => {
		if (!errors.orderNumber) {
			setOrder(data.orderNumber);
		}
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)} role='document'>
			<Flex gap={3} column style={{ margin: 'auto' }}>
				<Heading tag='h4'>{translations.enterOrderInfo}</Heading>
				<Paragraph>{translations.linkPdWithPair}</Paragraph>
				<Flex>
					<Input
						{...register('orderNumber', {
							required: translations.enterOrderNumber,
							pattern: { value: /^\d+$/, message: translations.orderNumberDigitRestriction },
							minLength: {
								value: MIN_VALUE,
								message: translations.orderNumberLengthRestriction,
							},
						})}
						placeholder={translations.orderNumber}
						errorMessage={errors.orderNumber?.message as string}
					/>
				</Flex>
				<Button
					type='submit'
					style={{ width: '100%' }}
					disabled={!orderNumber || orderNumber.length === 0 || !!errors.orderNumber}
				>
					{translations.submit}
				</Button>
				<Flex justify={'center'} gap={2} style={{ margin: '1.6rem' }}>
					<Tooltip message={translations.orderConfirmationEmail}>
						<Caption style={{ color: variables.blue2 }}>{translations.helpFindOrder}</Caption>
					</Tooltip>
				</Flex>
			</Flex>
		</form>
	);
};

export default OrderNumberForm;
