import { useRouter } from 'next/router';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { Flex, Lozenge, NonRx, Paragraph, Progressive, Readers, SingleVision, Title } from '@components';
import variables from '@styles/export.module.scss';
import { RX_TYPE } from '@utils/constants/base-skus';
import { RxOption } from '@ts/components';
import { useBFContext } from '@context';
import { formatCurrency } from '@utils/shopify';
import { LOCALE_CODES } from '@constants';
import { DISCOUNT_RATES, PRODUCT_TYPES, useIsVipMembership, useTranslation } from '@utils/index';
import { calculateDiscount } from '@utils/discount';
import styles from './PrescriptionLensExpansion.module.scss';

type RxOptionsProps = {
	option: RxOption;
	variantPriceDiff: {
		with: { amount: number; locale: (typeof LOCALE_CODES)[keyof typeof LOCALE_CODES]; currencyCode: string };
		without: { amount: number; locale: (typeof LOCALE_CODES)[keyof typeof LOCALE_CODES]; currencyCode: string };
	};
};

const PrescriptionIcons = {
	'single-vision': ({ className }) => <SingleVision className={className} />,
	'non-prescription': ({ className }) => <NonRx className={className} />,
	'readers': ({ className }) => <Readers className={className} />,
	'progressives': ({ className }) => <Progressive className={className} />,
};

const getTranslatedTexts = translator => {
	return {
		selected: translator('selected'),
		ourSpecialty: translator('our-specialty'),
		orderedPreviously: translator('ordered-previously'),
	};
};

const RxOptions = ({ option, variantPriceDiff }: RxOptionsProps) => {
	const { locale } = useRouter();
	const { applyDiscountMembership } = useIsVipMembership(PRODUCT_TYPES.LENS);
	const isBfAltCopy = useFeatureIsOn('is-bf-alt-copy');
	const { rxType, previousBaseFrames, hasPreviousBaseFrames } = useBFContext();
	const showCurr = locale === LOCALE_CODES.AU || locale === LOCALE_CODES.CA;
	const isOptionSelected = rxType === option.optionValue;
	const isProgressiveSelected = rxType === RX_TYPE.PROGRESSIVE;
	const dicountPercent = applyDiscountMembership ? DISCOUNT_RATES.PERCENT_15 : 0;
	const additionalPrice = isOptionSelected ? variantPriceDiff.without : variantPriceDiff.with;
	const discountedPrice = calculateDiscount(dicountPercent, additionalPrice.amount);
	const priceCopy = `+${formatCurrency({ ...additionalPrice, amount: discountedPrice }, showCurr)}`;
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);

	const isPreviouslyOrdered = hasPreviousBaseFrames && previousBaseFrames[0].rxType === option.optionValue;

	return (
		<Flex fullHeight fullWidth>
			{PrescriptionIcons[option.id]({ className: styles.rxIconLensExpansion })}
			<Flex
				align='center'
				gap={2}
				fullWidth
				style={{ flexGrow: 1, backgroundColor: 'transparent', padding: '1.2rem', borderRadius: '0.4rem' }}
			>
				<Flex column gap={1} align='start' style={{ flexGrow: 1 }}>
					<Flex align='center' gap={2} position={'relative'} fullWidth>
						<Paragraph className={styles['price']}>{priceCopy}</Paragraph>
					</Flex>
					<Title>{isBfAltCopy ? option.altCopy.title : option.copy}</Title>
					<Flex align='center' gap={2} position={'relative'} fullWidth>
						{isPreviouslyOrdered && (
							<Lozenge
								text={translations.orderedPreviously}
								shape='square'
								color={variables.gray1}
								backgroundColor={variables.blue1}
							/>
						)}
						{option.optionValue === RX_TYPE.PROGRESSIVE && !isProgressiveSelected && (
							<Lozenge
								text={translations.ourSpecialty}
								shape='square'
								color={variables.green2}
								backgroundColor={variables.greenLight}
							/>
						)}
						{isOptionSelected ? (
							<Lozenge
								text={translations.selected}
								shape='square'
								color={variables.green2}
								backgroundColor={variables.greenLight}
							/>
						) : null}
					</Flex>
					<Paragraph style={{ color: variables.gray4 }}>
						{isBfAltCopy ? option.altCopy.description : option.description}
					</Paragraph>
				</Flex>
			</Flex>
		</Flex>
	);
};

export default RxOptions;
