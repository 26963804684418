import { useCallback } from 'react';
import { useRouter } from 'next/router';
import {
	BUILD_FLOW_STEPS,
	CUSTOMER_JOURNEYS,
	getBfLensesStepTitle,
	getBfPrescriptionsStepTitle,
	LENSES_PACKAGES,
	LOCALE_CODES,
} from '@constants';
import { Caption, EducationModal, Flex, Lenses, Prescriptions, PrescriptionsLensExpansion, Title } from '@components';
import { useBFContext } from '@context';
import { RX_TYPE } from '@utils/constants/base-skus';
import { DEFAULT_INFO_TABS, INFO_TAB_DESCRIPTION_COPY, LENS_INFO_TABS, VARIFOCAL_INFO_TABS } from '@utils/constants/info-module';
import { useFeatureInLocale, useTranslation } from '@utils/index';
import styles from './PrescriptionStep.module.scss';

const getTranslatedTexts = translator => {
	return {
		selectAllThatApply: translator('select-all-that-apply'),
		handyLensAddOns: translator('handy-lens-add-ons'),
		enhanceYourPair: translator('enhance-your-pair'),
		prescriptionLensLineup: translator('prescription-lens-lineup'),
		prescriptionLensLowdown: translator('prescription-lens-lowdown'),
	};
};

const PrescriptionStep = () => {
	const { rxType, step, journey, rxOptions, lensPackage } = useBFContext();
	const { locale } = useRouter();
	const isCartUsability = useFeatureInLocale('is-cart-usability', LOCALE_CODES.US);
	const isSunLens = journey === CUSTOMER_JOURNEYS.SUNGLASSES;
	const isSubmitStep = step === BUILD_FLOW_STEPS.SUBMIT;
	const isCollectionsStep = step === BUILD_FLOW_STEPS.TOP_FRAMES;
	const isPackagesStep = step === BUILD_FLOW_STEPS.PACKAGE;
	const isLensesStep = step === BUILD_FLOW_STEPS.CUSTOMIZE;
	const isPrescriptionsStep = step === BUILD_FLOW_STEPS.LENS || isPackagesStep || isLensesStep;
	const isReadersSelected = rxType === RX_TYPE.READERS;
	const shouldDisplayLenses = ((isLensesStep && isSunLens) || isCollectionsStep) && (isReadersSelected || !isSunLens);
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);

	const getEducationTabs = useCallback(() => {
		const tabs = LENS_INFO_TABS;
		const rx = rxType.toLowerCase().split(/-| /)[0];
		const upgrades = rxOptions.find(option => option.optionValue.toLowerCase().startsWith(rx)).lenses;

		return tabs.filter(tab => {
			let shouldKeepTab = false;
			const tabTitle = tab.title.toLowerCase().split(/-| /)[0];
			upgrades.forEach(upgrade => {
				const upgradeStr = upgrade.handle.split('-')[0];
				if (upgradeStr === LENSES_PACKAGES.PREMIUM.toLowerCase()) {
					return false;
				}
				if (tabTitle === 'light' && lensPackage === LENSES_PACKAGES.BASIC) {
					return false;
				}
				if (upgradeStr === tabTitle) {
					shouldKeepTab = true;
				}
			});

			return shouldKeepTab;
		});
	}, [rxType, rxOptions, lensPackage, isSunLens]);

	return (
		<div className={styles.container}>
			{
				<Flex align='end' justify='between' gap={3} className={styles.title}>
					<Flex column>
						<Title
							style={{
								opacity: step !== BUILD_FLOW_STEPS.LENS && !isSunLens ? 0.5 : 1,
								fontSize: '1.4rem',
							}}
						>
							{isPrescriptionsStep || isSubmitStep || isSunLens
								? getBfPrescriptionsStepTitle(locale)
								: getBfLensesStepTitle(locale)}
						</Title>
						{!(isPrescriptionsStep || isSunLens) && !isSubmitStep && (
							<Caption small>({translations.selectAllThatApply})</Caption>
						)}
					</Flex>
					{isPrescriptionsStep ? (
						<div onClick={e => e.stopPropagation()}>
							{isLensesStep && !isSunLens ? (
								<EducationModal
									tabs={isSunLens ? getEducationTabs() : [...DEFAULT_INFO_TABS, ...getEducationTabs()]}
									title={translations.handyLensAddOns}
									tab={0}
									subTitle={translations.enhanceYourPair}
									infoCopy={INFO_TAB_DESCRIPTION_COPY}
								/>
							) : (
								<EducationModal
									tabs={locale === LOCALE_CODES.GB ? VARIFOCAL_INFO_TABS : DEFAULT_INFO_TABS}
									title={translations.prescriptionLensLineup}
									tab={0}
									subTitle={translations.prescriptionLensLowdown}
									infoCopy={INFO_TAB_DESCRIPTION_COPY}
								/>
							)}
						</div>
					) : null}
				</Flex>
			}
			<div className={styles['options-container']}>
				{isSunLens ? (
					<>
						<Prescriptions />
						{shouldDisplayLenses && <Lenses />}
					</>
				) : (
					<PrescriptionsLensExpansion isCartUsability={isCartUsability} />
				)}
			</div>
		</div>
	);
};

export default PrescriptionStep;
