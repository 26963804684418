import Link from 'next/link';
import variables from '@styles/export.module.scss';
import { Caption, Checkmark, Flex, Heading, Img, Paragraph, TypographyButton } from '@components';
import { isVideoUrl } from '@utils/urls';
import Tooltip from '../Tooltip';
import styles from './LensOptions.module.scss';
import { useTranslation } from '@utils/index';

type ColumnFields = {
	assetUrl: string;
	heading: string;
	description: string;
};

type LensOptionsProps = {
	title?: string;
	text?: string;
	columns?: ColumnFields[];
};

const getTranslatedTexts = translator => {
	return {
		blueLightFiltering: translator('blue-light-filtering'),
		perfectForLongScreenTime: translator('perfect-for-long-screen-time'),
		progressive: translator('progressive'),
		bifocalLensesModernAlternative: translator('bifocal-lenses-modern-alternative'),
		lightResponsive: translator('light-responsive'),
		lensesTransitionTint: translator('lenses-transition-tint'),
		sunLens: translator('sun-lens'),
		polarizedLenses: translator('polarized-lenses'),
		upgradeToPremiumLenses: translator('upgrade-to-premium-lenses'),
		lensOptions: translator('lens-options'),
		browserNoVideoSupport: translator('browser-no-video-support'),
		fsaHsaEligibleSentenceCase: translator('fsa-hsa-eligible-sentence-case'),
		fsaHsaEligibleCase: translator('fsa-hsa-eligible-case'),
		freeShippingAndReturn: translator('free-shipping-and-return'),
		freeTrial30Days: translator('free-trial-30-days'),
		shopWomen: translator('shop-women'),
		shopMen: translator('shop-men'),
		shopKids: translator('shop-kids'),
	};
};

const LensOptions = ({ title, text, columns }: LensOptionsProps) => {
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);

	const effectiveTitle = title ?? translations.lensOptions;
	const effectiveText = text ?? translations.upgradeToPremiumLenses;
	const effectiveColumns = columns ?? [
		{
			heading: translations.blueLightFiltering,
			description: translations.perfectForLongScreenTime,
			assetUrl:
				'https://videos.ctfassets.net/a8mjl2rcjwa7/3FS2rf6TmmU1ErDlwSVclV/e8611630ea4d8d3533943080a4521891/PAIR_Product_BlueLight.mp4',
		},
		{
			heading: translations.progressive,
			description: translations.bifocalLensesModernAlternative,
			assetUrl:
				'https://videos.ctfassets.net/a8mjl2rcjwa7/3qc2cFqxeifkJcK50e7alr/700f71d65fe29e36a3cf9cf5ac9db323/PAIR_Icon_Bifocals.mp4',
		},
		{
			heading: translations.lightResponsive,
			description: translations.lensesTransitionTint,
			assetUrl:
				'https://videos.ctfassets.net/a8mjl2rcjwa7/7l53lbAoE952TZEy8UqtNT/bfdd5e5b31fc2c5015d1db0c57cc3ccf/PAIR_Product_Progressive.mp4',
		},
		{
			heading: translations.sunLens,
			description: translations.polarizedLenses,
			assetUrl:
				'https://videos.ctfassets.net/a8mjl2rcjwa7/4TS99lToyt8ObauTMooFZI/1ece50c955e50b5bba712a208d7c13d4/PAIR_Product_Sunglasses.mp4',
		},
	];

	return (
		<div className={styles['container']} data-lens-options>
			<Heading tag='h2' className={styles['my-3']}>
				{effectiveTitle}
			</Heading>
			<Paragraph>{effectiveText}</Paragraph>
			<Flex justify={'between'} className={styles['reasons']}>
				{effectiveColumns.map(column => (
					<Flex align={'center'} className={styles['reason']} key={column.heading}>
						{isVideoUrl(column.assetUrl) ? (
							<video playsInline autoPlay muted loop id='heroVideo'>
								<source src={column.assetUrl} type='video/mp4' />
								{translations.browserNoVideoSupport}
							</video>
						) : (
							<Img
								style={{ objectFit: 'contain' }}
								src={column.assetUrl}
								noSrcset
								alt={column.heading}
								height={156}
								width={312}
							/>
						)}
						<Heading tag='h4'>{column.heading}</Heading>
						<Paragraph>{column.description}</Paragraph>
					</Flex>
				))}
			</Flex>

			<Flex justify={'evenly'} gap={4} className={styles['container__benefits']}>
				<Flex align='center' gap={3} className={styles['container__benefit']}>
					<Checkmark color={variables.green3} />
					<Caption>{translations.fsaHsaEligibleSentenceCase}</Caption>
					<Tooltip message={translations.fsaHsaEligibleCase} />
				</Flex>

				<Flex align='center' gap={3} className={styles['container__benefit']}>
					<Checkmark color={variables.green3} />
					<Caption>{translations.freeShippingAndReturn}</Caption>
				</Flex>

				<Flex align='center' gap={3} className={styles['container__benefit']}>
					<Checkmark color={variables.green3} />
					<Caption>{translations.freeTrial30Days}</Caption>
				</Flex>
			</Flex>

			<Flex align='center' justify='evenly' className={styles['container__links']}>
				<Link href={'eyeglasses/women'}>
					<TypographyButton style={{ color: variables.blue2 }}>{translations.shopWomen}</TypographyButton>
				</Link>
				<div className={styles['divider']}>|</div>
				<Link href={'eyeglasses/men'}>
					<TypographyButton style={{ color: variables.blue2 }}>{translations.shopMen}</TypographyButton>
				</Link>
				<div className={styles['divider']}>|</div>
				<Link href={'eyeglasses/kids'}>
					<TypographyButton style={{ color: variables.blue2 }}>{translations.shopKids}</TypographyButton>
				</Link>
			</Flex>
		</div>
	);
};

export default LensOptions;
