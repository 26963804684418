import { useRouter } from 'next/router';
import { CUSTOMER_JOURNEYS, LENSES_PACKAGES } from '@constants';
import { CheckboxUpsell, Flex, HeartSparkle, Loading, Paragraph } from '@components';
import { findLensPackageInLensOptions, LOCALE_DICT, useParseBaseFrameVariant, useTranslation } from '@utils/index';
import useBaseFrameVariant, {
	createBaseFrameVariant,
	useBaseFrameVariantPrices,
} from '@services/shopify/hooks/useBaseFrameVariant';
import { BASE_FRAME_LENS_OPTIONS } from '@utils/constants/base-skus';
import 'swiper/css';
import 'swiper/css/effect-fade';
import { Bundle } from '@ts/cart';
import styles from './LensUpsellSection.module.scss';

type LensUpsellSectionProps = {
	bundle: Bundle;
	isLoading: boolean;
};

const getTranslatedTexts = translator => {
	return {
		popularLensesRecommendation: translator('popular-lenses-recommendation'),
	};
};

const LensUpsellSection = ({ bundle, isLoading }: LensUpsellSectionProps) => {
	const router = useRouter();
	const { locale } = router;
	const { countryCode: country, languageCode: language } = LOCALE_DICT[locale];
	const { base, key: bundleKey } = bundle;
	const { frame } = base;
	const { variant, properties } = frame;
	const { option: fullVariantOption } = variant;
	const { _customerJourney } = properties;
	const isSunglasses = _customerJourney === CUSTOMER_JOURNEYS.SUNGLASSES;
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);

	const { frameColor, lensType, rxType } = useParseBaseFrameVariant(fullVariantOption);
	const isLightResponsiveInCart = lensType.includes(BASE_FRAME_LENS_OPTIONS.LIGHT_RESPONSIVE);

	const lensPack = !isSunglasses ? findLensPackageInLensOptions(lensType) : null;
	const BaseFrameVariant = useBaseFrameVariant({
		'handle': base.frame.variant.handle,
		'Color': frameColor,
		'Rx Type': rxType,
		'Lens': createBaseFrameVariant(lensType),
		country,
		language,
	});

	const { data: variantPrices } = useBaseFrameVariantPrices(base.frame.variant.handle, country, language);

	if (!base) return null;
	if (isLoading || BaseFrameVariant.isLoading) return <Loading />;

	return (
		!isSunglasses && (
			<Flex column gap={4} maxWidth className={styles.container}>
				<Flex gap={2}>
					<HeartSparkle height={24} width={24} />
					<Paragraph className={styles.title}>{translations.popularLensesRecommendation}</Paragraph>
				</Flex>
				<CheckboxUpsell
					base={base}
					lensUpsell={BASE_FRAME_LENS_OPTIONS.BLUE_LIGHT}
					bundleKey={bundleKey}
					variantPrices={variantPrices}
					currentVariant={BaseFrameVariant}
					disabled={isLightResponsiveInCart}
					lensPack={lensPack}
				/>
				{lensPack !== LENSES_PACKAGES.BASIC && (
					<CheckboxUpsell
						base={base}
						lensUpsell={BASE_FRAME_LENS_OPTIONS.LIGHT_RESPONSIVE}
						bundleKey={bundleKey}
						variantPrices={variantPrices}
						currentVariant={BaseFrameVariant}
						lensPack={lensPack}
					/>
				)}
			</Flex>
		)
	);
};

export default LensUpsellSection;
