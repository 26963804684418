import { useEffect } from 'react';
import { useInfiniteQuery, QueryFunction } from '@tanstack/react-query';
import { UseInfiniteQueryOptions } from '@tanstack/react-query/src/types';
import { Button, Flex, Loading } from '@components';
import { useOnScreen, useTranslation } from '@utils/hooks';
import styles from './InfiniteScroll.module.scss';

type InfiniteScrollProps = {
	type?: 'infinite' | 'button';
	onLoad: QueryFunction;
	pageRenderer: (page) => JSX.Element;
	options: Omit<UseInfiniteQueryOptions, 'queryKey' | 'queryFn'>;
};

const getTranslatedTexts = translator => {
	return {
		loadMore: translator('load-more'),
	};
};

/**
 * Wrapper of the useInfiniteScroll hook from react-infinite-scroll-hook.
 *
 * @param {string} type - the type of infinite scroll. It can be 'infinite' or 'button'.
 * @param {boolean} onLoad - function to fetch the paginated information.
 * @param {boolean} pageRenderer - builder function to render JSX Components.
 * @param {Object} options - options of the useInfiniteQuery hook.
 * @returns {JSX.Element} - Returns the embraced component in an infinite scroll component.
 */

const InfiniteScroll = ({ type = 'infinite', onLoad, pageRenderer, options }: InfiniteScrollProps) => {
	const { ref, isOnScreen } = useOnScreen();
	const { data, isFetching, fetchNextPage, hasNextPage } = useInfiniteQuery(['infiniteScroll'], onLoad, options);
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);

	useEffect(() => {
		if (type != 'button' && isOnScreen) {
			fetchNextPage();
		}
	}, [isOnScreen]);

	if (!data) return null;

	if (type == 'button') {
		return (
			<>
				{data.pages.map(pageRenderer)}
				{hasNextPage && (
					<Button
						extraClasses={styles.loadMoreButton}
						onClick={() => fetchNextPage()}
						color='transparent-light'
						linkStyle
					>
						<Flex justify='center' fullWidth>
							{isFetching ? <Loading className={styles.loadingDots} datatype={type} /> : translations.loadMore}
						</Flex>
					</Button>
				)}
			</>
		);
	}

	return (
		<>
			{data.pages.map(pageRenderer)}
			{(isFetching || hasNextPage) && (
				<Flex justify='center' ref={ref} fullWidth>
					<Loading className={styles.loadingDots} datatype={type} />
				</Flex>
			)}
		</>
	);
};

export default InfiniteScroll;
