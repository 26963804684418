import lensHeight from '@public/lens-height.png';
import bridgeWidth from '@public/bridge-width.png';
import lensWidth from '@public/lens-width.png';
import templeLength from '@public/temple-length.png';
import frameWidth from '@public/frame-width.png';
import { Accordian, Flex, Img, Paragraph } from '@components';
import { FrameSizingData } from '@ts/product';

import styles from './HorizontalFrameSizing.module.scss';
import { useTranslation } from '@utils/index';

type HorizontalFrameSizingProps = {
	frameSizings: FrameSizingData;
	name: string;
	isOpen?: boolean;
	onClick: () => void;
};

const getTranslatedTexts = translator => {
	return {
		sizingForProduct: (productName: string) => translator('sizing-for-product', { productName }),
		frameSizing: translator('frame-sizing'),
		frameWidthTitle: translator('frame-width-title'),
		frameWidthParagraph: translator('frame-width-paragraph'),
		lensHeightTitle: translator('lens-height-title'),
		lensHeightDescription: translator('lens-height-description'),
		bridgeWidthTitle: translator('bridge-width-title'),
		bridgeWidthDescription: translator('bridge-width-description'),
		lensWidthTitle: translator('lens-width-title'),
		lensWidthDescription: translator('lens-width-description'),
		templeLengthTitle: translator('temple-length-title'),
		templeLengthDescription: translator('temple-length-description'),
	};
};

const HorizontalFrameSizing = ({ frameSizings, name, isOpen = false, onClick }: HorizontalFrameSizingProps) => {
	const { units, lens, frame } = frameSizings;
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);

	return (
		<Accordian title={translations.frameSizing} isOpen={isOpen} onClick={onClick}>
			<Flex pad={4} gap={3} className={styles.container}>
				<div className={styles.contentCard}>
					<Img
						src={frameWidth.src}
						noSrcset
						alt={translations.sizingForProduct(name)}
						width={250}
						height={125}
						style={{ width: '100%', height: 'auto' }}
					/>
					<Flex column align='start' gap={1}>
						<Paragraph className={styles.title}>{translations.frameWidthTitle}</Paragraph>
						<Paragraph>{`${frame.width} ${units}`}</Paragraph>
						<Paragraph className={styles.description}>{translations.frameWidthParagraph}</Paragraph>
					</Flex>
				</div>
				<div className={styles.contentCard}>
					<Img
						src={lensHeight.src}
						noSrcset
						alt={translations.sizingForProduct(name)}
						width={250}
						height={125}
						style={{ width: '100%', height: 'auto' }}
					/>
					<Flex column align='start' gap={1}>
						<Paragraph className={styles.title}>{translations.lensHeightTitle}</Paragraph>
						<Paragraph>{`${lens.height} ${units}`}</Paragraph>
						<Paragraph className={styles.description}>{translations.lensHeightDescription}</Paragraph>
					</Flex>
				</div>
				<div className={styles.contentCard}>
					<Img
						src={bridgeWidth.src}
						noSrcset
						alt={translations.sizingForProduct(name)}
						width={250}
						height={125}
						style={{ width: '100%', height: 'auto' }}
					/>
					<Flex column align='start' gap={1}>
						<Paragraph className={styles.title}>{translations.bridgeWidthTitle}</Paragraph>
						<Paragraph>{`${frame.bridge + units}`}</Paragraph>
						<Paragraph className={styles.description}>{translations.bridgeWidthDescription}</Paragraph>
					</Flex>
				</div>
				<div className={styles.contentCard}>
					<Img
						src={lensWidth.src}
						noSrcset
						alt={translations.sizingForProduct(name)}
						width={250}
						height={125}
						style={{ width: '100%', height: 'auto' }}
					/>
					<Flex column align='start' gap={1}>
						<Paragraph className={styles.title}>{translations.lensWidthTitle}</Paragraph>
						<Paragraph>{`${lens.width + units}`}</Paragraph>
						<Paragraph className={styles.description}>{translations.lensWidthDescription}</Paragraph>
					</Flex>
				</div>
				<div className={styles.contentCard}>
					<Img
						src={templeLength.src}
						noSrcset
						alt={translations.sizingForProduct(name)}
						width={250}
						height={125}
						style={{ width: '100%', height: 'auto' }}
					/>
					<Flex column align='start' gap={1}>
						<Paragraph className={styles.title}>{translations.templeLengthTitle}</Paragraph>
						<Paragraph>{`${frame.temple + units}`}</Paragraph>
						<Paragraph className={styles.description}>{translations.templeLengthDescription}</Paragraph>
					</Flex>
				</div>
			</Flex>
		</Accordian>
	);
};

export default HorizontalFrameSizing;
