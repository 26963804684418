import { useState } from 'react';
import Link from 'next/link';
import { SubmitHandler, useForm } from 'react-hook-form';
import cn from 'classnames';
import { useRouter } from 'next/router';
import { EMAIL_VALIDATION_REG_EX, NEXT_APP_ENV } from '@constants';
import { getPasswordErrorMessage } from '@utils/constants/account';
import { Alert, Button, Chevron, Input } from '@components';
import { Heading, Paragraph, StyledWordEmphasizer } from '@components/typography';
import createCustomer from '@services/shopify/operations/create-customer';
import useLogin from '@services/shopify/operations/login';
import { trackRegister } from '@services/analytics/trackers';
import { CustomerCreateInput, CustomerErrorCode } from '@ts/shopify-storefront-api';
import paragraphStyles from '@components/typography/Paragraph.module.scss';
import linkStyles from '../Link/Link.module.scss';
import styles from './RegisterForm.module.scss';
import { useTranslation } from '@utils/index';

const getTranslatedTexts = translator => {
	return {
		goBack: translator('go-back'),
		createAccount: translator('create-account'),
		signIn: translator('sign-in-sentence-case'),
		alreadyHaveAccountQuestion: (signInText: string) => translator('already-have-account-question', { signInText }),
		accountBeingCreated: translator('account-being-created'),
		fillOutThisField: translator('fill-out-this-field'),
		firstName: translator('first-name'),
		lastName: translator('last-name'),
		includeAtSymbol: (xx: string) => translator('include-at-symbol', { xx }),
		email: translator('email'),
		password: translator('password'),
		termsOfServices: translator('terms-of-services'),
		privacyPolicy: translator('privacy-policy'),
		joinPairAgreement: (termsOfService: string, privacyPolicy: string) =>
			translator('join-pair-agreement', { termsOfService, privacyPolicy }),
		joinPair: translator('join-pair'),
	};
};

const RegisterForm = () => {
	const router = useRouter();
	const login = useLogin();
	const linkStyleClasses = cn(linkStyles['link'], styles.backLink, [linkStyles['link--small']]);
	const [submitErrors, setSubmitErrors] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [isAccountCreated, setIsAccountCreated] = useState(false);
	const isProd = process.env.NEXT_PUBLIC_APP_ENV === NEXT_APP_ENV.PROD;
	const { previous } = router.query;
	const { translator, locale } = useTranslation();
	const translations = getTranslatedTexts(translator);

	const {
		register,
		handleSubmit,
		reset,
		watch,
		formState: { errors, isDirty, isValid },
	} = useForm<CustomerCreateInput>({
		mode: 'all',
		defaultValues: {
			email: '',
			password: '',
		},
	});

	const onSubmit: SubmitHandler<CustomerCreateInput> = async data => {
		setIsLoading(true);
		try {
			await createCustomer(data);
			setIsLoading(false);
			setIsAccountCreated(true);
			setSubmitErrors([]);
			if (isProd) trackRegister(data);
			reset();
		} catch (error) {
			setIsLoading(false);
			setIsAccountCreated(false);
			reset();
			return setSubmitErrors(error.errors);
		}

		await login(data);
		router.push('/');
		reset();
	};

	const currentEmailInput = watch('email');
	const currentPasswordInput = watch('password');

	return (
		<form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
			<Link href={(previous as string) || '/account/login'}>
				<div
					className={linkStyleClasses}
					style={{ display: 'flex', alignItems: 'center', marginTop: '-0.5rem', fontSize: '1.2rem' }}
				>
					<Chevron direction='left' /> {translations.goBack}
				</div>
			</Link>
			<Heading tag='h1'>{translations.createAccount}</Heading>
			<Paragraph className={styles['signInMessage']}>
				<StyledWordEmphasizer
					text={translations.alreadyHaveAccountQuestion(translations.signIn)}
					emphasizedWords={translations.signIn}
					styledTexts={<Link href='/account/login'>{translations.signIn}</Link>}
				/>
			</Paragraph>
			{!!submitErrors.length &&
				submitErrors.map((error, index) => (
					// First time clients (without an account) will get a disabled customer error,
					// so we need to inform them that an invitation is on the way
					<Alert
						key={index}
						info={error?.code === CustomerErrorCode.CustomerDisabled}
						message={error.message.toString()}
					/>
				))}
			{isAccountCreated && <Alert info message={translations.accountBeingCreated} />}
			<div className={styles.nameLine}>
				<Input
					{...register('firstName', {
						required: translations.fillOutThisField,
					})}
					errorMessage={errors?.firstName?.message}
					id='CustomerFirstName'
					name='firstName'
					placeholder={translations.firstName}
					type='text'
					required
				/>
				<Input
					{...register('lastName', {
						required: translations.fillOutThisField,
					})}
					errorMessage={errors?.lastName?.message}
					id='CustomerLastName'
					name='lastName'
					placeholder={translations.lastName}
					type='text'
					required
				/>
			</div>
			<Input
				{...register('email', {
					required: translations.fillOutThisField,
					pattern: {
						value: EMAIL_VALIDATION_REG_EX,
						message: translations.includeAtSymbol(currentEmailInput),
					},
				})}
				errorMessage={errors?.email?.message}
				id='RegisterCustomerEmail'
				name='email'
				placeholder={translations.email}
				type='email'
				required
			/>
			<Input
				{...register('password', {
					required: translations.fillOutThisField,
					minLength: {
						value: 5,
						message: getPasswordErrorMessage(currentPasswordInput.length, locale),
					},
				})}
				errorMessage={errors?.password?.message}
				id='RegisterCustomerPassword'
				name='password'
				placeholder={translations.password}
				type='password'
				required
			/>
			<small className={`${styles['termsAndConditionsMessage']} ${paragraphStyles.default}`}>
				<StyledWordEmphasizer
					text={translations.joinPairAgreement(translations.termsOfServices, translations.privacyPolicy)}
					emphasizedWords={[translations.termsOfServices, translations.privacyPolicy]}
					styledTexts={[
						<Link key='terms-of-service' href='/terms-of-service'>
							{translations.termsOfServices}
						</Link>,
						<Link key='privacy-policy' href='/privacy-policy'>
							{translations.privacyPolicy}
						</Link>,
					]}
				/>
			</small>
			<div className={styles.buttons}>
				<Button
					showSpinner={isLoading}
					fullWidth
					type='submit'
					label={translations.joinPair}
					disabled={!isDirty || !isValid}
				/>
			</div>
		</form>
	);
};

export default RegisterForm;
