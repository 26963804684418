import React, { useState } from 'react';
import { useRouter } from 'next/router';
import { SubmitHandler, useForm } from 'react-hook-form';
import { MIN_VALUE } from '@constants';
import { getPasswordErrorMessage } from '@utils/constants/account';
import { Alert, Button, Heading, Input } from '@components';
import { resetPassword } from '@services/shopify';
import styles from './LoginForm.module.scss';
import { useTranslation } from '@utils/index';

type ResetPasswordFormValues = {
	password: string;
	confirmPassword: string;
};

const getTranslatedTexts = translator => {
	return {
		resetAccountPassword: translator('reset-account-password'),
		fillOutThisField: translator('fill-out-this-field'),
		password: translator('password'),
		confirmPassword: translator('confirm-password'),
		resetPasswordLabel: translator('reset-password-label'),
		passwordsNotMatch: translator('passwords-not-match'),
	};
};

const ResetPasswordForm = () => {
	const router = useRouter();
	const { translator, locale } = useTranslation();
	const translations = getTranslatedTexts(translator);

	const [customerId, resetToken] = router.query.urlInfo;

	const {
		register,
		reset,
		handleSubmit,
		watch,
		formState: { errors },
	} = useForm<ResetPasswordFormValues>({
		mode: 'onBlur',
		defaultValues: {
			password: '',
			confirmPassword: '',
		},
	});

	const [submitErrors, setSubmitErrors] = useState([]);
	const currentPasswordInput = watch('password');
	const confirmPasswordInput = watch('confirmPassword');
	const [loading, setLoading] = useState(false);

	const onSubmit: SubmitHandler<ResetPasswordFormValues> = async data => {
		const customerGID = `gid://shopify/Customer/${customerId}`;

		try {
			setLoading(true);
			await resetPassword(customerGID, { resetToken, password: data.password });
			setSubmitErrors([]);
			router.push('/account/login');
		} catch (error) {
			console.error(error);
			setLoading(false);
			reset();
			return setSubmitErrors(error.errors);
		}
	};

	return (
		<form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
			<Heading tag='h1' style={{ marginBottom: '2.4rem' }}>
				{translations.resetAccountPassword}
			</Heading>
			{!!submitErrors.length &&
				submitErrors.map(({ message }, index) => {
					return <Alert message={message.toString()} key={index} />;
				})}
			<Input
				{...register('password', {
					required: translations.fillOutThisField,
					minLength: {
						value: MIN_VALUE,
						message: getPasswordErrorMessage(currentPasswordInput.length, locale),
					},
				})}
				errorMessage={errors?.password?.message}
				id='ResetCustomerPassword'
				name='password'
				placeholder={translations.password}
				type='password'
				withIcon
			/>
			<Input
				{...register('confirmPassword', {
					required: translations.fillOutThisField,
					minLength: {
						value: MIN_VALUE,
						message: getPasswordErrorMessage(currentPasswordInput.length, locale),
					},
					validate: value => value === currentPasswordInput || translations.passwordsNotMatch,
				})}
				errorMessage={errors?.confirmPassword?.message}
				id='ResetCustomerPasswordConfirm'
				name='confirmPassword'
				placeholder={translations.confirmPassword}
				type='password'
				withIcon
			/>

			<div className={styles.buttons}>
				<Button
					showSpinner={loading}
					data-login-button
					fullWidth
					type='submit'
					label={translations.resetPasswordLabel}
					disabled={!(currentPasswordInput.length >= MIN_VALUE) || !(confirmPasswordInput === currentPasswordInput)}
				/>
			</div>
		</form>
	);
};

export default ResetPasswordForm;
