import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { Customer } from '@ts/shopify-storefront-api';
import { useOrders } from '@services/poms';
import useOrderDetails from '@services/poms/hooks/useOrderDetails';
import { getShopifyIdFromGID, LOCALE_CODES, useTranslation } from '@utils/index';
import { DiscountBanner, Flex, Grid, Heading, Loading, OrderDetail, OrderTracking, RewardCard, Spacer } from '@components';
import { PairPlusCard } from '@components/accountCard';

import styles from './LoggedInAccountCard.module.scss';

type LoggedInAccountCardProps = {
	customer?: Customer;
};

const getTranslatedTexts = translator => {
	return {
		welcomeBackTitleCase: (customerName: string) => translator('welcome-back-title-case', { customerName }),
	};
};

const LoggedInAccountCard = ({ customer }: LoggedInAccountCardProps) => {
	const [loading, setLoading] = useState(true);
	const userId = getShopifyIdFromGID(customer?.id);
	const isMembership = useFeatureIsOn('is-membership');
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);

	const { data: orderData, isLoading } = useOrders({ userId, page: 1 });
	const orderNumber = orderData?.orders?.[0]?.order_number;
	const { data: order, isLoading: isDetailLoading } = useOrderDetails({ id: orderNumber });
	const { locale } = useRouter();
	const orderCompleted = order?.last_status.title === 'Delivered';
	const orderCancelled = order?.last_status.title === 'Cancelled';
	const isBfroDiscount = useFeatureIsOn('is-bfro-discount');

	useEffect(() => {
		if (orderData && !isDetailLoading) {
			setLoading(false);
		}
		if (!orderData && !isLoading) {
			setLoading(false);
		}
	}, [orderData, isDetailLoading, isLoading]);

	if (!customer) return null;

	if (loading) {
		return (
			<div className={styles['loading-wrapper']}>
				<Loading className={styles['loading-spinner']} />
			</div>
		);
	}

	const isUs = locale === LOCALE_CODES.US;
	const isCa = locale === LOCALE_CODES.CA;
	const showReferAFriend = isUs || isCa;
	const heightCards = !isMembership ? 'normal' : 'small';

	return (
		<div className={styles.section}>
			<div className={styles.sectionWrapper}>
				<Heading tag='h3' className={styles.heading}>
					{translations.welcomeBackTitleCase(customer.firstName)}
				</Heading>
				<Spacer size={'2.4rem'} />
				{isBfroDiscount && <DiscountBanner margin='bottom' />}
				<Grid columns={showReferAFriend ? 2 : 1} gap={3} className={styles.cardContainer}>
					{orderCompleted || orderCancelled ? <OrderDetail order={order} /> : <OrderTracking order={order} />}
					{showReferAFriend && (
						<Flex column fullWidth gap={3} fullHeight>
							<RewardCard userId={userId} height={heightCards} />
							{isUs && <PairPlusCard userId={userId} height={heightCards} />}
						</Flex>
					)}
				</Grid>
			</div>
		</div>
	);
};

export default LoggedInAccountCard;
