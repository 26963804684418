import cn from 'classnames';
import { useRouter } from 'next/router';
import { LOCALE_DICT, getViewAllTopsDefault } from '@constants';
import { COLLECTION_LISTS } from '@constants/contentful';
import { Loading, TopsTileNavigation } from '@components';
import { NormalizedCollection } from '@ts/product';
import { useCollectionsQuery } from '@utils/react-query';
import styles from './CollectionCarousel.module.scss';
import { useTranslation } from '@utils/index';

export type CollectionCarouselProps = {
	collections: Array<NormalizedCollection>;
	removePadding?: boolean;
};

const getTranslatedTexts = translator => {
	return {
		checkoutCollections: translator('checkout-collections'),
		chooseOurTopFrames: translator('choose-our-top-frames'),
	};
};

export const CollectionCarousel = ({ removePadding = false }: CollectionCarouselProps) => {
	const classes = cn(styles['section'], {
		[styles['removePadding']]: removePadding,
	});
	const { locale } = useRouter();
	const { countryCode: country, languageCode: language } = LOCALE_DICT[locale];
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);
	const VIEW_ALL_TOPS_DEFAULT = getViewAllTopsDefault(locale);

	const { data: collections, isLoading } = useCollectionsQuery(COLLECTION_LISTS.ACTIVE, {
		queryKey: 'active-collections',
		type: 'active',
		withProducts: false,
		country,
		language,
		queryRefreshVars: [locale],
	});

	if (!collections && isLoading) {
		return (
			<div className={styles['loading-wrapper']}>
				<Loading className={styles['loading-spinner']} />
			</div>
		);
	}

	return (
		<TopsTileNavigation
			collections={[VIEW_ALL_TOPS_DEFAULT, ...Object.values(collections)]}
			heading={translations.checkoutCollections}
			subHeading={translations.chooseOurTopFrames}
			className={classes}
		/>
	);
};

export default CollectionCarousel;
