import React, { useState, DragEvent, ChangeEvent, useEffect, ReactNode } from 'react';
import { Button, Flex, Lozenge } from '@components/common';
import { Caption, TypographyButton } from '@components/typography';
import variables from '@styles/export.module.scss';
import { getBadgeTypes } from '@constants';
import { useTranslation } from '@utils/index';
import styles from './DragAndDropFileUpload.module.scss';

interface DragAndDropFileUploadProps {
	onFileSelect?: (e: ChangeEvent<HTMLInputElement>) => void;
	accept?: string[];
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	fileRef?: any;
	checked: boolean;
	lozenge?: {
		label: string | ReactNode;
		backgroundColor?: string;
		color?: string;
	};
	clearInput?: () => void;
}

const getTranslatedTexts = translator => {
	return {
		edit: translator('edit'),
		remove: translator('remove'),
		photoUploaded: translator('photo-uploaded'),
		clickToUploadFile: translator('click-to-upload-file'),
		uploadFile: translator('upload-file'),
		uploadedFileRestrictions: (fileTypes: string, maxSize: string) =>
			translator('uploaded-file-restrictions', { fileTypes, maxSize }),
		invalidFileType: translator('invalid-file-type'),
	};
};

const DragAndDropFileUpload: React.FC<DragAndDropFileUploadProps> = ({
	onFileSelect,
	accept,
	fileRef,
	checked,
	lozenge,
	clearInput,
}) => {
	const [file, setFile] = useState<File | null>(null);
	const [isDragging, setIsDragging] = useState(false);
	const [error, setError] = useState<boolean>(false);
	const { translator, locale } = useTranslation();
	const translations = getTranslatedTexts(translator);
	const BADGE_TYPES = getBadgeTypes(locale);

	const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.stopPropagation();
		setIsDragging(true);
	};

	const handleDragLeave = (e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.stopPropagation();
		setIsDragging(false);
	};

	const handleDrop = (e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.stopPropagation();
		setIsDragging(false);

		if (e.dataTransfer.files && e.dataTransfer.files[0]) {
			const file = e.dataTransfer.files[0];
			const extension = file.name.split('.').pop()?.toLowerCase();

			if (accept && !accept.includes(extension)) {
				setError(true);
				return;
			}

			setError(null);
			setFile(file);
			if (onFileSelect) {
				const event = { target: { files: e.dataTransfer.files } } as unknown as ChangeEvent<HTMLInputElement>;
				onFileSelect(event);
			}
		}
	};

	const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target.files && e.target.files[0]) {
			const file = e.target.files[0];
			const extension = file.name.split('.').pop()?.toLowerCase();

			if (accept && !accept.includes(extension)) {
				setError(true);
				e.target.value = '';
				return;
			}

			setError(false);
			setFile(file);
			onFileSelect?.(e);
		}
	};

	useEffect(() => {
		if (error) {
			setTimeout(() => {
				setError(false);
			}, 3000);
		}
	}, [error]);

	const highlightWord = ({ text, highlightedWord }: { text: string; highlightedWord: string }) => {
		const escapedDelimiter = highlightedWord.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
		const splitedText = text.split(new RegExp(`(${escapedDelimiter})`)).filter(Boolean);
		return (
			<>
				{splitedText.map((text, index) => {
					return text === highlightedWord ? (
						<span style={{ color: variables.blue1 }} key={index}>
							{highlightedWord}
						</span>
					) : (
						<span key={index}>{text}</span>
					);
				})}
			</>
		);
	};

	return (
		<div
			className={styles.container}
			onClick={e => {
				checked && !file && e.preventDefault();
			}}
		>
			<div
				className={`${styles.dropzone} ${isDragging ? styles.dragging : ''}`}
				onDragOver={handleDragOver}
				onDragLeave={handleDragLeave}
				onDrop={handleDrop}
			>
				<input
					type='file'
					id='fileInput'
					className={styles.input}
					onChange={handleFileChange}
					accept={accept?.map(ext => `.${ext}`).join(', ')}
					tabIndex={-1}
					ref={fileRef}
				/>
				<label htmlFor='fileInput' className={styles.label}>
					{checked ? (
						<Flex column fullWidth>
							<div className={styles.actionsContainer} onClick={e => e.preventDefault()}>
								{file && (
									<Flex justify='end' gap={2}>
										<Button size='small' linkStyle onClick={() => fileRef?.current?.click()}>
											{translations.edit}
										</Button>
										<TypographyButton small style={{ color: variables.gray4 }}>
											|
										</TypographyButton>
										<Button
											size='small'
											linkStyle
											onClick={e => {
												e.preventDefault();
												setFile(null);
												clearInput?.();
											}}
										>
											{translations.remove}
										</Button>
									</Flex>
								)}
							</div>
							<label htmlFor='fileInput'>
								<Flex column gap={2} align='center' style={{ marginTop: '2rem' }}>
									<img src='/upload-succesful.svg' alt='Upload Sucessful' width={24} height={24} />
									<Caption color={variables.green2}>{translations.photoUploaded}</Caption>
									{file && (
										<Caption small color={variables.gray3}>
											{file.name}
										</Caption>
									)}
								</Flex>
							</label>
						</Flex>
					) : (
						<Flex column gap={2} align='center'>
							<img src='/upload-icon.svg' alt='Upload' width={24} height={24} />
							<Lozenge {...BADGE_TYPES.BEST_SELLER} shape='square' size='regular' extraClasses={styles.lozenge}>
								{lozenge?.label}
							</Lozenge>
							<Caption>
								{highlightWord({
									text: translations.clickToUploadFile,
									highlightedWord: translations.uploadFile,
								})}
							</Caption>
							<Caption small color={variables.gray3}>
								{translations.uploadedFileRestrictions('PNG, jpeg', '2MB')}
							</Caption>
						</Flex>
					)}
				</label>
				{error && (
					<div style={{ paddingTop: '1rem' }}>
						<Lozenge text={translations.invalidFileType} color='white' backgroundColor='red' shape='square' />
					</div>
				)}
			</div>
		</div>
	);
};

export default DragAndDropFileUpload;
