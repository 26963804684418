import cn from 'classnames';
import { Button, Caption, Flex, Loading, Paragraph, TypographyButton } from '@components';
import variables from '@styles/export.module.scss';
import { OrderDetailPOMS } from '@ts/poms/orders';
import { RewardDetail } from '@ts/yotpo/rewards';
import { FAQ_URL } from '@constants';
import { useIsMobile, useTranslation } from '@utils/hooks';
import styles from './AccountCard.module.scss';
import { OrderTracking } from './OrderTracking';
import { OrderDetail } from './OrderDetail';
import { RewardsDetail } from './RewardsDetail';
import { PairPlusDetail } from './PairPlusDetail';

export type AccountCardProps = {
	order?: OrderDetailPOMS;
	rewardDetail?: RewardDetail;
	primaryAction?: 'none' | 'details' | 'rewards' | 'pair-plus';
	secondaryAction?: 'none' | 'question' | 'question-membership';
	title?: string;
	label?: string;
	subCopy?: string;
	subtitle?: string;
	type?: 'details' | 'tracking' | 'rewards' | 'pair-plus';
	className?: string;
	isDataLoading?: boolean;
	primaryHref?: string;
	height?: 'normal' | 'small';
};

const getTranslatedTexts = translator => {
	return {
		howRedeemPoints: translator('how-redeem-points'),
		viewOrderDetails: translator('view-order-details'),
		goToRewards: translator('go-to-rewards'),
		manageMembership: translator('manage-membership'),
		howUseStoreCredit: translator('how-use-store-credit'),
	};
};

const AccountCard = ({
	rewardDetail,
	primaryAction = 'details',
	secondaryAction = 'none',
	title,
	label,
	subCopy,
	subtitle,
	type = 'details',
	order,
	className,
	isDataLoading,
	primaryHref,
	height = 'normal',
}: Omit<AccountCardProps, 'children'>) => {
	const classes = cn(styles.cardContainer, className, {
		[styles.rewardsWidget]: type === 'rewards' || type === 'pair-plus',
		[styles['cardContainer--small']]: height === 'small',
	});

	const content = type => {
		switch (type) {
			case 'details':
				return <OrderDetail order={order} />;
			case 'tracking':
				return <OrderTracking order={order} />;
			case 'rewards':
				return <RewardsDetail rewardDetail={rewardDetail} />;
			case 'pair-plus':
				return <PairPlusDetail />;
			default:
				return null;
		}
	};

	return (
		<Flex column className={classes} justify='between' data-account-card={type}>
			{isDataLoading ? (
				<Loading className={styles['loading-spinner']} />
			) : (
				<>
					<div>
						{type !== 'rewards' && (
							<Copy title={title} label={label} subCopy={subCopy} subtitle={subtitle} type={type} />
						)}
						<Flex fullWidth column>
							{content(type)}
						</Flex>
					</div>
					<div className={styles.buttons}>
						<ButtonGroup
							primaryAction={primaryAction}
							secondaryAction={secondaryAction}
							order={order}
							primaryHref={primaryHref}
						/>
					</div>
				</>
			)}
		</Flex>
	);
};

type CopyProps = {
	title?: string;
	label?: string;
	subCopy?: string;
	subtitle?: string;
	type?: 'details' | 'tracking' | 'rewards' | 'pair-plus';
};

const Copy = ({ title, label, subCopy, subtitle, type }: CopyProps) => {
	const classes = cn(styles.label, {
		[styles.rewardsLabel]: type === 'rewards' || type === 'pair-plus',
		[styles.ordersLabel]: type === 'details' || type === 'tracking',
	});

	return (
		<Flex fullWidth column>
			<Flex justify='between' align='center'>
				<Paragraph style={{ fontWeight: '500' }}>{title}</Paragraph>
				{subtitle && (
					<Caption small color={variables.gray3} style={{ height: '2rem' }}>
						{' '}
						{/* Inline style allows for centering, this font sits offcenter within its own line height  */}
						{subtitle}
					</Caption>
				)}
			</Flex>
			{label && (
				<div className={classes}>
					<TypographyButton small>{label}</TypographyButton>
				</div>
			)}
			{subCopy && <Paragraph>{subCopy}</Paragraph>}
		</Flex>
	);
};

type ButtonGroupProps = Required<Pick<AccountCardProps, 'primaryAction' | 'secondaryAction' | 'order' | 'primaryHref'>>;

const ButtonGroup = ({ primaryAction, secondaryAction, order, primaryHref }: ButtonGroupProps) => {
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);
	const isMobile = useIsMobile();

	return (
		<Flex
			column={(primaryAction !== 'rewards' && primaryAction !== 'pair-plus') || isMobile}
			justify='start'
			fullWidth
			gap={4}
		>
			{primaryAction === 'details' && (
				<Button
					extraClasses={styles.cleanButton}
					label={translations.viewOrderDetails}
					href={`/account/orders/${order ? order.order_number : ''}`}
					color='white'
					size='small'
				/>
			)}
			{primaryAction === 'rewards' && (
				<Button
					extraClasses={styles.cleanButton}
					label={translations.goToRewards}
					href={'/rewards'}
					color='transparent-dark'
					size='small'
				/>
			)}
			{primaryAction === 'pair-plus' && (
				<Button
					extraClasses={styles.cleanButton}
					label={translations.manageMembership}
					href={primaryHref}
					color='transparent-dark'
					target='_blank'
					size='small'
				/>
			)}
			{secondaryAction === 'question' && (
				<Button
					extraClasses={styles.cleanButton}
					href={`${FAQ_URL}/articles/23209827264535-Rewards-and-Accounts-US-CD#h_01HWX0469DPE2CMVK6DAHXSH88`}
					color='transparent-dark'
					size='small'
					target='_blank'
				>
					{translations.howRedeemPoints}
				</Button>
			)}
			{secondaryAction === 'question-membership' && (
				<Button
					extraClasses={styles.cleanButton}
					href='https://shop.paireyewear.com/pages/membership'
					color='transparent-dark'
					size='small'
					target='_blank'
				>
					{translations.howUseStoreCredit}
				</Button>
			)}
		</Flex>
	);
};

export default AccountCard;
