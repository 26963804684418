import { useCallback } from 'react';
import { Flex, Select, TypographyButton } from '@components';
import { READER_STRENGTHS, READERS_STRENGTH_COPY } from '@constants';
import { useBFContext } from '@context';
import { useTranslation } from '@utils/index';
import styles from './PrescriptionLensExpansion.module.scss';

const getTranslatedTexts = translator => {
	return {
		select: translator('select'),
	};
};

const ReadersStrength = () => {
	const { readerRx, setReaderRx } = useBFContext();
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);

	const updateReaderStrength = useCallback(strength => {
		const cleanStr: (typeof READER_STRENGTHS)[number] = strength.replace('+', '');
		setReaderRx(cleanStr);
	}, []);

	return (
		<div className={styles['readers']} onClick={e => e.stopPropagation()}>
			<Flex justify='between' align='center'>
				<TypographyButton small>{READERS_STRENGTH_COPY}</TypographyButton>
				<Select
					buttonProps={{ size: 'small' }}
					values={READER_STRENGTHS.map(rs => `+${rs}`)}
					handler={updateReaderStrength}
					placeholder={readerRx ? `+${readerRx}` : translations.select}
				/>
			</Flex>
		</div>
	);
};

export default ReadersStrength;
