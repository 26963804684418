import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useRouter } from 'next/router';
import { EMAIL_VALIDATION_REG_EX, NUMBERS_VALIDATION_REG_EX } from '@constants';
import { Button, Flex, Heading, Input, NotificationBox } from '@components';
import { getOrderByOrderNumber } from '@services/shopify/operations/admin';
import { useCustomer } from '@services/shopify';
import styles from './TrackOrderForm.module.scss';
import { useTranslation } from '@utils/index';

type TrackOrderInput = {
	email: string;
	orderNumber: string;
};

const getTranslatedTexts = translator => {
	return {
		enterValidOrderNumber: translator('enter-valid-order-number'),
		manageYourOrder: translator('manage-your-order'),
		checkOrderStatus: translator('check-order-status'),
		notFound: translator('not-found'),
		fillOutThisField: translator('fill-out-this-field'),
		enterOnlyNumeric: translator('enter-only-numeric'),
		orderNumber: translator('order-number'),
		invalidEmailAddress: translator('invalid-email-address'),
		emailAddress: translator('email-address'),
		submit: translator('Submit'),
		trackOrderQuestions: translator('track-order-questions'),
		createPairAccount: translator('create-pair-account'),
	};
};

const TrackOrderForm = () => {
	const router = useRouter();
	const Customer = useCustomer();
	const {
		register,
		handleSubmit,
		watch,
		formState: { errors },
	} = useForm<TrackOrderInput>({
		mode: 'onBlur',
		defaultValues: {
			email: '',
			orderNumber: '',
		},
	});
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);

	const [infoAlert, setInfoAlert] = useState<string | null>();
	const [loading, setLoading] = useState(false);

	const onSubmit: SubmitHandler<TrackOrderInput> = async data => {
		setLoading(true);
		const response = await getOrderByOrderNumber(data.orderNumber, data.email);
		setInfoAlert(response.length == 0 ? translations.enterValidOrderNumber : null);
		if (response.length > 0) {
			const path = `/track-order/${data.orderNumber}?email=${data.email}`;
			router.push(path);
			return;
		}
		setLoading(false);
	};

	const currentEmailInput = watch('email');
	const currentOrderNumberInput = watch('orderNumber');
	const isValidEmail: boolean = EMAIL_VALIDATION_REG_EX.test(currentEmailInput);
	const isValidOrderNumber: boolean = NUMBERS_VALIDATION_REG_EX.test(currentOrderNumberInput);

	useEffect(() => {
		if (Customer.data) {
			router.push('/account/orders');
		}
	}, [Customer, router]);

	const showForm = !Customer.isLoading && !Customer.data;

	return (
		<>
			{showForm && (
				<Flex column align='center' justify='center'>
					<form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
						<Heading tag='h4' style={{ textAlign: 'center' }}>
							{translations.manageYourOrder}
						</Heading>
						<div className={styles.subheader}>{translations.checkOrderStatus}</div>
						{infoAlert && (
							<NotificationBox
								title={translations.notFound}
								body={infoAlert}
								type='error'
								className={styles.notification}
							/>
						)}
						<div className={styles.form}>
							<Input
								{...register('orderNumber', {
									required: translations.fillOutThisField,
									pattern: {
										value: NUMBERS_VALIDATION_REG_EX,
										message: translations.enterOnlyNumeric,
									},
								})}
								onKeyDown={event => ['.', '-', '+', 'e', 'E'].includes(event.key) && event.preventDefault()}
								errorMessage={errors?.orderNumber?.message}
								id='CustomerOrderNumber'
								name='orderNumber'
								placeholder={translations.orderNumber}
								type='number'
								min='0'
								className={styles.input}
								pattern='\d*'
							/>
							<Input
								{...register('email', {
									required: translations.fillOutThisField,
									pattern: {
										value: EMAIL_VALIDATION_REG_EX,
										message: translations.invalidEmailAddress,
									},
								})}
								errorMessage={errors?.email?.message}
								id='TrackOrderCustomerEmail'
								name='email'
								placeholder={translations.emailAddress}
								type='email'
								className={styles.input}
							/>
						</div>
						<div className={styles.buttons}>
							<Button
								showSpinner={loading}
								fullWidth
								type='submit'
								label={translations.submit}
								disabled={!(isValidEmail && isValidOrderNumber && currentOrderNumberInput.length > 0)}
							/>
						</div>
						<div className={styles.footer}>
							<div className={styles['footer-text']}>{translations.trackOrderQuestions}</div>
							<Button
								linkStyle
								size='small'
								label={translations.createPairAccount}
								href={`/account/register?previous=${router.asPath}`}
							/>
						</div>
					</form>
				</Flex>
			)}
		</>
	);
};
export default TrackOrderForm;
