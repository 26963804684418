import { useState } from 'react';
import { useRouter } from 'next/router';
import { SubmitHandler, useForm } from 'react-hook-form';
import { MIN_VALUE } from '@constants';
import { getPasswordErrorMessage } from '@utils/constants/account';
import { Alert, Body, Button, Heading, Input } from '@components';
import useActivateCustomer from '@services/shopify/operations/activate-customer';
import styles from './LoginForm.module.scss';
import { useTranslation } from '@utils/index';

type ActiveAccountFormValues = {
	password: string;
	confirmPassword: string;
};

const getTranslatedTexts = translator => {
	return {
		activateAccount: translator('activate-account'),
		createYourPassword: translator('create-your-password'),
		accountBeingActivated: translator('account-being-activated'),
		fillOutThisField: translator('fill-out-this-field'),
		password: translator('password'),
		confirmPassword: translator('confirm-password'),
		declineInvitation: translator('decline-invitation'),
		passwordsNotMatch: translator('passwords-not-match'),
	};
};

const ActiveAccountForm = () => {
	const router = useRouter();
	const activateCustomer = useActivateCustomer();
	const { translator, locale } = useTranslation();
	const translations = getTranslatedTexts(translator);

	const [customerId, activationToken] = router.query.urlInfo;

	const {
		register,
		reset,
		handleSubmit,
		watch,
		formState: { errors },
	} = useForm<ActiveAccountFormValues>({
		mode: 'onBlur',
		defaultValues: {
			password: '',
			confirmPassword: '',
		},
	});

	const [submitErrors, setSubmitErrors] = useState([]);
	const currentPasswordInput = watch('password');
	const confirmPasswordInput = watch('confirmPassword');
	const [loading, setLoading] = useState(false);
	const [accountActivated, setAccountActivated] = useState(false);

	const onSubmit: SubmitHandler<ActiveAccountFormValues> = async data => {
		const customerGID = `gid://shopify/Customer/${customerId}`;

		try {
			setLoading(true);
			await activateCustomer(customerGID, { activationToken, password: data.password });
			setAccountActivated(true);
			router.push('/');
		} catch (error) {
			console.error('Error activating account:', error);
			setLoading(false);
			reset();
			setAccountActivated(false);
			return setSubmitErrors(error.errors);
		}
	};

	return (
		<form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
			<Heading tag='h1' style={{ marginBottom: '2.4rem' }}>
				{translations.activateAccount}
			</Heading>
			<Body style={{ marginBottom: '2.4rem' }}>{translations.createYourPassword}</Body>
			{!!submitErrors.length &&
				submitErrors.map(({ message }, index) => {
					return <Alert message={message} key={index} />;
				})}
			{accountActivated && <Alert info message={translations.accountBeingActivated} />}
			<Input
				{...register('password', {
					required: translations.fillOutThisField,
					minLength: {
						value: MIN_VALUE,
						message: getPasswordErrorMessage(currentPasswordInput.length, locale),
					},
				})}
				errorMessage={errors?.password?.message}
				id='CustomerPassword'
				name='password'
				placeholder={translations.password}
				type='password'
				withIcon
			/>
			<Input
				{...register('confirmPassword', {
					required: translations.fillOutThisField,
					minLength: {
						value: MIN_VALUE,
						message: getPasswordErrorMessage(currentPasswordInput.length, locale),
					},
					validate: value => value === currentPasswordInput || translations.passwordsNotMatch,
				})}
				errorMessage={errors?.confirmPassword?.message}
				id='CustomerPasswordConfirm'
				name='confirmPassword'
				placeholder={translations.confirmPassword}
				type='password'
				withIcon
			/>

			<div className={styles.buttons}>
				<Button
					showSpinner={loading}
					data-login-button
					fullWidth
					type='submit'
					label={translations.activateAccount}
					disabled={!(currentPasswordInput.length >= MIN_VALUE) || !(confirmPasswordInput === currentPasswordInput)}
				/>
				<Button color='white' fullWidth label={translations.declineInvitation} href='/' />
			</div>
		</form>
	);
};

export default ActiveAccountForm;
