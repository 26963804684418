import { memo } from 'react';
import { Control, FieldValues } from 'react-hook-form';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Text } from '@contentful/rich-text-types';
import { useRouter } from 'next/router';
import { AccordianCell, ControlledMeasurementInput, Flex } from '@components';
import { useContentfulAsset } from '@services/contentful/hooks';
import { RxFieldOption } from '@utils/constants/rxss';
import { VisualAssetEntrySkeletonType, VisualAssetFields } from '@ts/contentful';
import { RxssPomsPayload } from '@ts/rxss';

type MeasurementCellProps = {
	control: Control<FieldValues, RxssPomsPayload>;
	id: string;
	measurements: RxFieldOption['measurements'];
	handleUpdateActiveInput: (x: string) => void;
};

const MeasurementCell = ({ control, handleUpdateActiveInput, id, measurements }: MeasurementCellProps) => {
	const { locale } = useRouter();
	const ContentfulRequest = useContentfulAsset<VisualAssetEntrySkeletonType>({
		id,
		query: {
			'content_type': 'visualAsset',
			'fields.slug[in]': measurements.map(m => m.cmsSlug).join(','),
			locale,
		},
	});

	return (
		<Flex column fullWidth backgroundColor='white' borderRadius={3} pad={4} style={{ borderRadius: '1.6rem' }}>
			{measurements.map(({ inputs, centerInstruction }, index) => {
				const fields = ContentfulRequest?.data?.[index]?.fields ?? ({} as VisualAssetFields);
				const { media, description, title, shortDescription } = fields;

				return (
					<AccordianCell
						key={`accordianCellFragment-${id}-${index}`}
						title={title}
						notice={shortDescription}
						imageSrc={media?.fields?.file?.url as string}
						description={!!description?.content ? (description?.content?.[0]?.content?.[0] as Text)?.value : null}
						initiallyExpanded={false}
					>
						{inputs.map(({ id: inputId, label, values }) => {
							return (
								<ControlledMeasurementInput
									key={`controlledMeasurementInput-${inputId}`}
									control={control}
									handleUpdateActiveInput={handleUpdateActiveInput}
									label={label}
									id={inputId}
									values={values}
									centerInstruction={centerInstruction}
								/>
							);
						})}
					</AccordianCell>
				);
			})}
		</Flex>
	);
};

export default memo(MeasurementCell);
