import { Caption, Flex, Paragraph, Star } from '@components';
import variables from '@styles/export.module.scss';
import { useTranslation } from '@utils/index';
import styles from './CartReview.module.scss';
interface CartReviewProps {
	description: string;
	name: string;
	location: string;
	rating: number;
}

const getTranslatedTexts = translator => {
	return {
		userCommentLocation: (user: string, location: string) => translator('user-comment-location', { user, location }),
	};
};

function CartReview({ description, name, location, rating }: CartReviewProps) {
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);

	const highlightUserName = (text: string, userName: string) => {
		const escapedDelimiter = userName.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
		const splitedText = text.split(new RegExp(`(${escapedDelimiter})`)).filter(Boolean);
		return (
			<>
				{splitedText.map((text, index) => {
					return text === userName ? (
						<Caption small color={variables.blue2} key={index}>
							{' '}
							{userName}{' '}
						</Caption>
					) : (
						<Caption small color={variables.gray4} key={index}>
							{' '}
							{text}{' '}
						</Caption>
					);
				})}
			</>
		);
	};

	return (
		<Flex column gap={4} className={styles.container}>
			<Paragraph>{`“${description}”`}</Paragraph>
			<Flex gap={2}>{Array(rating).fill(<Star color={variables.orange1} stroke={0} />)}</Flex>
			<Flex gap={2}>{highlightUserName(translations.userCommentLocation(name, location), name)}</Flex>
		</Flex>
	);
}

export default CartReview;
