import { Flex, Img } from '@components';
import styles from './GiftCardImage.module.scss';
import { useTranslation } from '@utils/index';

const GiftCardImage = ({ selectedVariant }) => {
	const { translator } = useTranslation();

	return (
		<Flex className={styles.image} justify='center' align='center'>
			<Img src={selectedVariant.image.url} width={512} height={340} alt={translator('gift-card')} />
		</Flex>
	);
};

export default GiftCardImage;
