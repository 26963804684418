import { Fragment } from 'react';
import { Body, EqualSign, Flex, Heading, PlusSign } from '@components';
import { HowItWorksProps } from '@ts/index';
import styles from './HowItWorks.module.scss';
import { useTranslation } from '@utils/index';

const getTranslatedTexts = translator => {
	return {
		howItWorks: translator('how-it-works'),
	};
};

const HowItWorks = ({ heading, page, showSigns, showDetails = true, blocks }: HowItWorksProps) => {
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);
	const effectiveHeading = heading ?? translations.howItWorks;

	return (
		<section className={styles.container} data-component-type='howItWorks' data-page-type={page}>
			<Heading tag='h2'>{effectiveHeading}</Heading>
			<Flex align='center'>
				{blocks.map((column, index) => (
					<Fragment key={`fragment-${column.heading}`}>
						<div key={column.heading}>
							{column.image.contentType === 'video/mp4' ? (
								<video autoPlay playsInline loop muted>
									<source src={column.image.url} />
								</video>
							) : (
								<img src={column.image.url} alt={column.heading} height={172} width={343} />
							)}
							<Heading tag='h5'>{column.heading}</Heading>
							{showDetails && <Body>{column.description}</Body>}
						</div>
						{showSigns && index === 0 && (
							<div data-type='plus-sign'>
								<PlusSign />
							</div>
						)}

						{showSigns && index === 1 && (
							<div data-type='equal-sign'>
								<EqualSign />
							</div>
						)}
					</Fragment>
				))}
			</Flex>
		</section>
	);
};

export default HowItWorks;
